import { InputError } from '@kit/inputs/input-error';
import { BaseInputIconStyled, BaseInputInputStyled, BaseInputInputWithIconStyled, BaseInputStyled } from './styled';
import { BaseInputProps } from './types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const BaseInput: React.FC<BaseInputProps> = props => {
  const {
    inputRef,
    inputStyle,
    className,
    inputClassName,
    iconClassName,
    icon,
    disabled,
    hasError,
    errorText,
    onChange,
    onClick,
    ...restProps
  } = props;
  const change: React.ChangeEventHandler<HTMLInputElement> = event => {
    const {
      value
    } = event.target;
    onChange?.(value, event);
  };
  return _jsxs(BaseInputStyled, {
    onClick: onClick,
    children: [_jsxs(BaseInputInputWithIconStyled //
    , {
      className: className,
      disabled: disabled,
      hasError: hasError,
      children: [_jsx(BaseInputInputStyled //
      , {
        ref: inputRef,
        style: inputStyle,
        className: inputClassName,
        disabled: disabled,
        onChange: change,
        ...restProps
      }), !icon ? null : _jsx(BaseInputIconStyled, {
        className: iconClassName,
        children: icon
      })]
    }), _jsx(InputError, {
      errorText: errorText
    })]
  });
};
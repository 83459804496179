import { AppFCC } from '@types';
import { InputErrorStyled } from './styled';
import { InputErrorProps } from './types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const InputError: AppFCC<InputErrorProps> = props => {
  const {
    errorText
  } = props;
  if (!errorText) {
    return null;
  }
  return _jsx(InputErrorStyled, {
    children: errorText
  });
};